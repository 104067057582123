#wrapper.dark-mode {
    .vulcan-search-item:hover {
        background: #363636;
    }
}

.vulcan-search {
    position: relative;
    height: 2.2rem;
    overflow: visible;

    .vulcan-search-container {
        position: absolute;
        width: 97%;
    }

    .vulcan-search-dropdown {
        position: relative;
        z-index: 50;
        margin: 0;
        margin-right: inherit;
        width: 100%;
        min-height: 3rem;
        max-height: 15rem;
        overflow-y: scroll;
        padding: 0;
    }

    .vulcan-search-item {
        display: flex;
        height: 3rem;
        transition: background-color 0.35s;
        justify-content: space-between;
        padding-left: 3%;
        padding-right: 3%;
        align-items: center;

        p {
            height: 1rem;
            margin: 0;
        }

        &:hover {
            background-color: #fafbfc;
            cursor: pointer;
        }

        .consent {
            color: red;
        }
    }
}
