#wrapper.dark-mode {
    .vulcan-card {
        background-color: #2f2f2f;
    }
}

.vulcan-card {
    background-color: white;
    min-height: 10rem;
    margin: 5%;
    margin-top: 0%;
    border-radius: 0.5rem;
    padding: 2% 3%;
    box-shadow: 0 0px 14px 0 rgba(0, 0, 0, 0.16);

    .radios {
        background-color: transparent;
    }
}
