.accordion-item-wrapper {
    display: flex;

    .checkbox {
        &.disabled {
            cursor: not-allowed;
            opacity: 0.6;
        }
    }

    .column {
        width: auto;
    }
}
