#deal-batch-update-modal {
    z-index: 999999999;
    background-color: rgb(47, 144, 68);
    position: fixed;
    bottom: 10px;
    border-radius: 10px;
    margin:10px;
    padding: 15px;
    width: calc(100vw - 499px);

    .modal-header {
        display: flex;
        justify-content: flex-end;
        color: white;
        margin-bottom: 10px;
        .modal-close:hover {
            cursor: pointer;
        }
    }

    .widget {
        margin: 5px;
        .form {
            .columns {
                justify-content: space-between;
                .label {
                    color: white;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    max-width: 50%;
                }
                .selection {
                    display: flex;
                    justify-content: end;
                    align-items: center;
                }
            }
        }
    }
}