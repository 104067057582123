.table {
    .faux-cell {
        p {
            color: black;
        }
    }
}

#wrapper.dark-mode {
    .table {
        .faux-cell {
            p {
                color: white;
            }
        } 
    }
}