$vulcan-button-width: 13%;

#verbal-template {
    width: inherit;

    .vulcan-action-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }
}

.verbal-card {
    display: grid;
    text-align: left;
    align-items: start;
    margin-top: 2%;
    margin-bottom: 2%;

    .hidden {
        display: none;
    }

    .vulcan-parent-div {
        margin-bottom: 0%;

        &>* {
            // margin: 0%;
            margin-bottom: 1%;
        }
    }

    h3 {
        margin-bottom: 1%;
    }
}

.verbal-year {
    display: grid;
    column-gap: 2%;
    grid-template-columns: $vulcan-button-width auto;

    label {
        grid-row: 1;
    }

    // for all children
    &>* {
        grid-row: 2;
    }
}

.verbal-input-1b {
    display: grid;
    // grid-template-rows: fit-content fit-content;
    column-gap: 2%;
    grid-template-columns: auto $vulcan-button-width;

    label {
        grid-row: 1;
    }

    // for all children
    &>* {
        grid-row: 2;
    }
}

.verbal-input-2b {
    display: grid;
    grid-template-rows: fit-content fit-content;
    column-gap: 2%;
    grid-template-columns: auto repeat(2, $vulcan-button-width);

    label {
        grid-row: 1;
    }

    // for all children
    &>* {
        grid-row: 2;
    }
}
