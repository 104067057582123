/* Set custom system values */
$primary: #2f9044;
$primary-light: #2f9044;
$purple: #8e2f8e;
$orange: #FFA500;

/* Custom Colors */
$primary-lighter: #ebedef;
$zeus-dark: #22502c;
$nav-background-color: $zeus-dark;

@import "~@truenorthmortgage/olympus/src/sass/style";

/* Custom */
body {
    p {
        line-height: 1.4;
        color: #000;
    }
}
.margin-left {
    margin-left: 10px;
}

.large textarea {
    height: 300px;
}
.large .ck-content {
    height: 200px;
}
.modal-box:has(.large) {
    max-width: 880px;
}
.modal-box:has(.x-large) {
    max-width: 60%;
}
.premier {
    color: $primary;
}
#wrapper {
    background-color: #fff;
    #login {
        background-color: $primary-lighter;
    }

    .login-inner {
        #logo-2fa {
            text-align: center;
            margin: 8px;
            color: #2f9044;
        }

        button#two-factor-submit {
            margin-bottom: 15px;
        }

        a {
            color: #2f9044;
        }
    }

    #nav {
        // background-color: $zeus-dark;
        // .nav-inner {
        //     .logo {
        //         background-color: $primary-lighter;
        //     }
        // }
        .bottom-bar-nav {
            #available{
                .available {
                    display: none;
                }
                .unavailable {
                    display: block;
                }
                &.true {
                    .available {
                        display: block;
                    }
                    .unavailable {
                        display: none;
                    }
                }
            }
            .dark-mode-toggle-item {
                .switch-container .switch {
                    margin: 0 19px 0 0;
                    padding: 0;
                }

                display: flex;
                align-items: center;
            }
        }
    }

    #body-content {
        .widget {
            .widget-inner {
                p {
                    line-height: 1.4;
                }
            }
        }
        .copy {
            @include copy-styles;
        }
    }
}

/* Think Formula Tables, you'll notice the markup associated with this styling is smartadmin, we haven't had time to rewrite the service yet so we will just style the markup we get back for now*/
#think-term-formulas {
    @include flex;
    &.hidden {
        display: none;
    }
    table {
        &:nth-child(even) {
            margin: 2px 0px 0px 1px;
        }
        &:nth-child(odd) {
            margin: 2px 1px 0px 0px;
        }
        &.show-only-fifths {
            tbody {
                tr {
                    display: none;
                    &.fifth {
                        display: table-row;
                    }
                }
            }
        }
        thead {
            border-top: 1px solid $default-border-color;
            border-left: 1px solid $default-border-color;
            border-right: 1px solid $default-border-color;
            tr {
                th {
                    border-bottom: 1px solid $default-border-color;
                    padding: 10px 10px 10px 10px;
                    text-align: center;
                }
            }
        }
        tbody {
            border-left: 1px solid $default-border-color;
            border-right: 1px solid $default-border-color;
            tr {
                &:nth-child(odd) {
                    background-color: $gray;
                }
                td {
                    text-align: center;
                    border-bottom: 1px solid $default-border-color;
                    padding: 10px 10px 10px 10px;
                }
            }
        }
    }
}

.three-column-list {
    @include flex;
    ul {
        flex-grow: 1;
    }
}

//DealHeader Widget Styles
.deal-header {
    @include flex-wrap;
    h2 {
        color: black;
        @include text-rem(24);
        @include proxima-nova(600);
        margin-bottom: 8px;
    }
    h3 {
        color: $charcoal;
    }
    .subjects {
        margin: 0px 0px 0px 0px;
        span {
            border-right: 1px solid $extra-gray;
            padding: 0px 10px 0px 10px;
            &:first-of-type {
                padding: 0px 10px 0px 0px;
            }
            &:last-of-type {
                border-right: 0px;
            }
        }
    }
    .action-buttons {
        margin: 20px 0px 0px 0px;
    }
    .actions-dropdown {
        @include breakpoint(medium) {
            margin: 20px 0px 0px 0px;
        }
    }
    p {
        margin-bottom: 10px;
        .bold {
            color: black;
        }
    }
    > .left {
        width: 60%;
        padding-right: 20px;
        @include flex;
        @include breakpoint(medium) {
            width: 100%;
            padding-right: 0px;
        }
        .badge-wrapper {
            margin: 0px 20px 0px 0px;
            .badge {
                width: 59px;
                height: auto;
                display: block;
            }
        }
    }
    > .right {
        width: 40%;
        @include breakpoint(medium) {
            width: 100%;
            margin-bottom: 20px;
        }
    }
}

/* Next Action Well widget styles */
.next-action-well {
    background: $primary-lighter;
    border: 1px solid $primary;

    box-shadow: 0 1px 1px #ececec;
    -webkit-box-shadow: 0 1px 1px #ececec;
    -moz-box-shadow: 0 1px 1px #ececec;
    position: relative;

    padding: 24px;
    border-radius: 3px;

    min-height: 20px;
    margin-bottom: 20px;

    display: grid;
    grid-template-columns: auto 25%;
    grid-template-rows: auto;
    grid-template-areas:
        "title actions"
        "description actions";
    align-items: center;

    & > div:first-child {
        display: flex;
        align-items: center;
        grid-area: title;
    }

    & > div:nth-child(2) {
        grid-area: actions;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    & > div:nth-child(3) {
        grid-area: description;
        margin-top: 1em;
    }

    table.na-meta tbody tr th {
        padding-top: 0.75rem;
    }

    div span.na-title {
        font-size: 1.5em;
        font-weight: bold;
        color: $primary;
        margin-right: 0.5em;
    }
}

/* Modal Document Styles */
.max-size {
    .modal-document {
        iframe {
            height: 100vh;
        }
    }
}
.modal-document {
    padding: 20px 20px 20px 20px;
    iframe {
        width: 100%;
        height: auto;
        border: none;
    }
    img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0px auto;
    }
}

/* Inline Document Styles */
.inline-document {
    width: 100%;
    padding: 0px 30px 30px 30px;
    height: 100vh;
    iframe {
        width: 100%;
        height: 100vh;
        border: none;
    }
    img {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        margin: 0px auto;
    }
}

/* Formatted Note for Tables*/
.widget.table {
    .faux-row {
        &.green {
            background: $mint;
        }
        .faux-cell {
            &.formatted-note {
                background: #efefef;
                padding: 20px;
                h4 {
                    margin-bottom: 10px;
                }
                p {
                    margin: 0px;
                    color: $charcoal;
                    margin: 0px 0px 10px 0px;
                    &.mice {
                        margin: 0px;
                        color: $gray-blue;
                    }
                }
            }
            &.warning {
                color: #c90000;
            }
        }
    }
}

/* Deal Status-specific row styles */

.widget.table {
    table {
        tr {
            &.pre-approval, &.conditional {
                > td {
                    background-color: #ffc184 !important;
                }
                &:hover > td {
                    background-color: #ffae5f !important;
                }
            }

            &.broker-complete, &.response-received, &.closed {
                > td {
                    background-color: #90df90 !important;
                }
                &:hover > td {
                    background-color: #80d180 !important;
                }
            }

            &.entered-into-velocity {
                > td {
                    background-color: #9dd9ec !important;
                }
                &:hover > td {
                    background-color: #89c9de !important;
                }
            }

            &.obsolete {
                > td {
                    background-color: #edd99d !important;
                }
                &:hover > td {
                    background-color: #dbc991 !important;
                }
            }

            &.approved-waiting-on-client, &.approved-waiting-on-lender, &.approved-waiting-on-broker {
                > td {
                    background-color: #fffbbf !important;
                }
                &:hover > td {
                    background-color: #ede9a3 !important;
                }
            }

            &.submitted {
                > td {
                    background-color: #f2afaf;
                }
                &:hover > td {
                    background-color: #e59999;
                }
            }

            &.on-hold {
                > td {
                    background-color: #ccc !important;
                }
                &:hover > td {
                    background-color: #bbb !important;
                }
            }
        }
    }
}

/* Queue Styles */
div.queue-wrapper {
    width: 100%;
}

h3.queue-header {
    margin: 5px 0;
    font-size: 1.25em;
}

div.queue-status {
    display: grid;
    margin-bottom: 1em;

    grid-template-columns: 85px 18px auto;
}

div.queue-status:not(.top) > div:not(.queue-deals) {
    margin: auto 0;
}

div.queue-status.top > div:not(.queue-deals) {
    margin-top: 4px;
}

div.queue-status > div:first-child {
    width: 220px;
}

div.queue-status > div:nth-child(2) {
    flex-grow: 1;
}

div.queue-status > div.queue-deals, div.closer-queue > div.queue-deals {
    /*display: flex;*/
    /*overflow-x: auto;*/
    position: relative;
}

div.queue-deals > a {
    border: 1px solid #DDD;
    flex-grow: 1;
    text-align: center;
    position: relative;
    top: 0;
    color: #DDD;
    padding: 0.25em;
    font-weight: bold;
    display: inline-block;
    min-width: 22px;
    height: 28px;
    line-height: 20px;
    margin: 1px;

    cursor: default;
    text-decoration: none;
}

/*div.queue-deals > a.premier:first-child {
    padding-left: 1.5em;
}*/

div.queue-deals > a.requested {
    background-color: $primary
}

div.queue-deals > a.empty {
    border: 0;
    width: auto;
}

div.queue-deals > a.premier.yours {
    cursor: default;
}

div.queue-deals > a.yours {
    border: 1px solid $primary;
    color: $primary;
    background-color: transparentize($primary, 0.7);
    cursor: pointer;
}

div.queue-deals > a.concierge {
    background-color: $purple;
    color: #fff;
}

div.queue-deals > a.wasabi-pod-deals {
    background-color: $orange;
    color: #fff;
}

div.queue-deals > a.in-training-deals {
    background-color: $light-blue;
    color: #fff;
}

/* Correspondence Timeline Styles */
.correspondence-info {
    @include flex-wrap;
    align-items: baseline;
    line-height: 1.4;
    .action-buttons {
        .button {
            padding: 3px 10px 3px 10px;
        }
    }
}
.correspondence-heading {
    font-weight: bold;
    margin: 0px 10px 0px 0px;
    border-radius: 4px;
    padding: 4px 10px 4px 10px;
    background: $primary-lighter;
    &.new {
        @include proxima-nova(600);
        background: $primary;
        color: white;
    }
}
.correspondence-item-headline {
    margin: 0px 10px 0px 0px;
}

/* Document Specialist Queue */
#doc-specialist-queue {
    .doc-specialist-queue-entry {
        width: 100%;
        max-width: 100%;
        display: inline-block;

        div {
            display: grid;

            &:nth-child(1) {
                > span {
                    margin-bottom: 0.25em;
                    font-weight: bold;
                    &:first-child {
                        font-size: 1.2em;
                    }
                }
            }

            &:nth-child(2) {
                grid-template-columns: 25% auto;
                > span {
                    margin-bottom: 0.25em;
                    &:last-child, &:nth-last-child(2) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

div.custom-cell-text-label, input.custom-cell-text {
    display: inline-block;
    flex-shrink: 0;
}

input.custom-cell-text {
    max-width: 200px;
}

/* Tooltip text */
.tooltip .claim-pay-data {
  visibility: hidden;
  width: 300px;
  background-color: #ffffff;
  border: 1px solid #dfe2e5;
  color: #000;
  text-align: center;
  padding: 5px;
  top: 150%;
  left: 50%;
  margin-left: -125px;
  border-radius: 5px;
  position: absolute;
  z-index: 9999;
}

.tooltip .claim-pay-data::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #dfe2e5 transparent;
}

.tooltip:hover .claim-pay-data {
  visibility: visible;
}

/* documents action icons */
.reduce-padding {
    padding: 9px;
    margin-right: 5px;
}

/* thinker closer queue */
.deals-queue a:hover {
    cursor: pointer;
}


#login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray;
    height: 100vh;
    width: 100%;

    #login-form {
        max-width: 300px;
    }
}

.tooltip {
    position: relative;
}

.tooltip .tooltip-table {
  visibility: hidden;
  background-color: #ffffff;
  border: 1px solid #dfe2e5;
  color: #000;
  text-align: center;
  padding: 5px;
  top: 150%;
  left: 100%;
  margin-left: -125px;
  border-radius: 5px;
  position: absolute;
  z-index: 9999;
}

.tooltip .tooltip-table::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 35%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #dfe2e5 transparent;
}

.tooltip:hover .tooltip-table {
  visibility: visible;
}

.modal {
    .form {
        .warning {
            font-weight: bold !important;
            color: #C90100 !important
        }
    }
    .buttons {
        .button {
            margin-bottom: 0;
        }
    }

    iframe {
        width: 100%;
        min-height: 60vh;
    }

    // done to overide the style of the selector dropdown (messes with multi-select styling)
    ul {
        all: unset;
    }
}


.opacity-0 {
    opacity: 0;
}

.select2-container {
    z-index: 99998;
}

#login {
    @include flex-wrap;
    width: 100%;
    min-height: 100vh;
    display: inline-flex;
    align-items: center;
    margin: 0px auto;
    padding: 22px 22px 22px 22px;
    .login-inner {
        width: 100%;
        max-width: 300px;
        margin: auto auto auto auto;
        position: relative;
        a.logo {
            display: block;
            width: 100%;
            margin: 0px 0px 36px 0px;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

// Stacked Icons
// -------------------------
$fa-css-prefix: 'fa';
$fa-inverse: white;
.#{$fa-css-prefix}-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;
  }
  .#{$fa-css-prefix}-stack-1x, .#{$fa-css-prefix}-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .#{$fa-css-prefix}-stack-1x { line-height: inherit; }
  .#{$fa-css-prefix}-stack-2x { font-size: 2em; }
  .#{$fa-css-prefix}-inverse { color: $fa-inverse; }

.max-width {
    max-width: 100%;
}

.property {
    display: flex;
    .property-wrapper {
        margin-bottom: 30px;
    }
    .property-table {
        width: 50%;
    }
}

#wrapper.dark-mode {
    #main {
        #content-block {
            .deal-header {
                h2, h3 {
                    color: white;

                    &.subjects {
                        a {
                            color: #0075ff;
                        }
                    }
                }
                .button.disabled.not-allowed {
                    background: #eeeeee;
                }
            }

            .thinker-queue-wrapper {
                .tooltip {
                    .tooltip-table {
                        color: white;
                        background-color: #232323;
                    }
                }
            }

            .queue-deals {
                a {
                    background-color: #2f2f2f;
                    border: none;
                    &.requested {
                        background-color: $primary
                    }
                    &.concierge {
                        background-color: $purple
                    }
                    &.wasabi-pod-deals {
                        background-color: $orange;
                        color: #fff;
                    }
                    &.in-training-deals {
                        background-color: $light-blue;
                        color: #fff;
                    }
                }
            }

            .correspondence-heading {
                background: #2f2f2f;
                color: white;
            }

            .tag.primary {
                background: #2f9044;
            }
        }

        // removes strange afterglow background
        .checkbox {
            background: transparent;
        }

        .modal-title {
            color: white;
        }

        // multi-select dark mode styling. The dropdown is rendered outside of the root div, and doesn't receive the dark-mode class
        .select2-selection__choice__display {
            color: black;
        }

        .select2-selection {
            background-color: rgb(65, 65, 65);
        }

        .select2-selection__rendered {
            background: transparent;
            border-color: transparent;
        }
    }

    .radios {
        background-color: transparent;
    }

    .ck.ck-toolbar.ck-toolbar_grouping, .ck-dropdown__panel_visible {
        background-color: #232323;
        border: none;

        .ck {
            color: white;
        }
    }

    .ck.ck-editor__main > .ck-editor__editable {
        background-color: #414141;
        border: none;
    }
}

.no-text-transform {
    text-transform: none !important;
}

.inline-horizontal {
    display: flex;
    flex-direction: row;
    gap: 2%;
    padding-bottom: 0%;

    .column {
        padding: 0;
    }
}

.inline-vertical {
    display: flex;
    flex-direction: column;
    gap: 9%;
}

.no-left-padding{
    padding-left: 0;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    padding-bottom: 22px;
}
