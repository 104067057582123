.custom-cell-text-container {
    display: flex;
    justify-content: space-between;

    .custom-cell-text-label-container {
        align-items: center;
        display: flex;
        gap: 10px;
        .custom-cell-text-label {
            white-space: nowrap;
        }

        .checkbox, .checkbox .checkmark {
            margin: 0 !important;
        }
    }

    .custom-cell-text-toggle {
        display: flex;
        align-items: center;
    }
}

.document-name {
    display: flex;
    justify-content: left;
    align-items: center;
}
